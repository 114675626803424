import React, {useEffect, useState} from 'react'

import scss from './scrolltopbtn.module.scss';

import {AiOutlineArrowUp} from 'react-icons/ai';

const ScrollTopBtn = () => {

  const [scrollButton, setScrollButton] = useState(false);

function scrollToTop() {
          window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
          });
}

useEffect(() => {
  const handleScroll = _ => { 
    if (window.pageYOffset > 20) {
      setScrollButton(true);
    } else {
      setScrollButton(false);
    }
  }
  window.addEventListener('scroll', handleScroll)
  return _ => {
    window.removeEventListener('scroll', handleScroll)
  }
}, []);

  return (
    <button className={scrollButton ? `${scss["scrolltopbtn"]} ${scss["active"]}` : `${scss["scrolltopbtn"]}`} onClick={scrollToTop}><AiOutlineArrowUp/></button>
  )
}

export default ScrollTopBtn