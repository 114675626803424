import React from 'react'

import { lazy, Suspense } from 'react';

import Loading from '../../global-components/loading/Loading';

const OpenContent = lazy(() => import('./home-components/OpenContent'));
const Breadcrumb = lazy(() => import('../../global-components/breadcrumb/Breadcrumb'));

const Open = () => {
  return (
      <>
        <Suspense fallback={<Loading/>}>
          <Breadcrumb navigations={["Domů", "Otevírací doba"]} to={["/", "/oteviraci-doba"]}/>
          <OpenContent/>
        </Suspense>
      </>
  )
}

export default Open