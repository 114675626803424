import React, { lazy, Suspense } from 'react';

import Loading from '../../global-components/loading/Loading';

const LazyBreadcrumb = lazy(() => import('../../global-components/breadcrumb/Breadcrumb'));
const LazyGalleryContent = lazy(() => import('./gallery-components/content/GalleryContent'));

const Gallery = () => {
  return (
    <>
      <Suspense fallback={<Loading/>}>
        <LazyBreadcrumb navigations={["Domů", "Galerie"]} to={["/", "/galerie"]} />
        <LazyGalleryContent />
      </Suspense>
    </>
  );
};

export default Gallery;