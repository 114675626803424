import React from 'react'

import { lazy, Suspense } from 'react';

import Loading from '../../global-components/loading/Loading';

const PriceContent = lazy(() => import('./price-components/PriceContent'));
const Breadcrumb = lazy(() => import('../../global-components/breadcrumb/Breadcrumb'));

const Price = () => {
  return (
      <>
        <Suspense fallback={<Loading/>}>
          <Breadcrumb navigations={["Domů", "Ceník"]} to={["/", "/cenik"]}/>
          <PriceContent/>
        </Suspense>
      </>
  )
}

export default Price