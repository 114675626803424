import React, { lazy, Suspense } from 'react';

import Loading from '../../global-components/loading/Loading';

const LazyMain = lazy(() => import('./home-components/main/Main'));
const LazySkill = lazy(() => import('./home-components/skill/Skill'));
const LazyOffer = lazy(() => import('./home-components/offer/Offer'));

const Home = () => {
  return (
    <>
      <Suspense fallback={<Loading/>}>
        <LazyMain />
        <LazySkill />
        <LazyOffer />
      </Suspense>
    </>
  );
};

export default Home;