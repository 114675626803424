import React from 'react'

import scss from './loading.module.scss';

const Loading = () => {
  return (
    <div className={scss["loader"]}>
    </div>
  )
}

export default Loading