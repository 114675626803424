import React, {useState, useRef, useEffect} from 'react'

import scss from './header.module.scss';

import Logo from '../../assets/logo.png';

import { Link } from 'react-router-dom';

const Header = () => {

  const [activeNav, setActiveNav] = useState(false);

  const btnRef = useRef();
  const linksRef = useRef();

  const toggleNav = () => {
    setActiveNav(!activeNav);
  };

  useEffect(() => {
    const closeNav = (e) => {
      if (e.target !== btnRef.current && e.target !== linksRef.current && e.target.tagName !== "svg" &&e.target.tagName !== "path") {
        setActiveNav(false);
      }
    };

    document.body.addEventListener("click", closeNav);

    return () => document.body.removeEventListener("click", closeNav);
  }, []);

  return (
    <header className={scss["header"]}>
      <div className={scss["header_left"]}>
          <Link to="/"><img src={Logo} alt="Logo" className={scss["header_logo"]} /></Link>
      </div>


        <div className={scss["header_right"]}>
          <ul className={activeNav ? `${scss["header_links"]} ${scss["active"]}` : scss["header_links"]} ref={linksRef}>
            <Link to="/" className={scss["header_link"]}>Domů</Link>
            <Link to="/cenik" className={scss["header_link"]}>Ceník</Link>
              <Link to="/darkovy-poukaz" className={scss["header_link"]}>Poukaz</Link>
            <Link to="/kontakt" className={scss["header_link"]}>Kontakt</Link>
            <Link to="/oteviraci-doba" className={scss["header_link"]}>Otevírací doba</Link>
            <Link to="/galerie" className={scss["header_link"]}>Galerie</Link>
          </ul>
          <button className={scss["hamburger"]} ref={btnRef} onClick={() => toggleNav()}>
              <span onClick={(e) => {e.stopPropagation(); toggleNav();}}></span>
              <span onClick={(e) => {e.stopPropagation(); toggleNav();}}></span>
              <span onClick={(e) => {e.stopPropagation(); toggleNav();}}></span>
          </button>
        </div>
    </header>
  )
}

export default Header