import React, { useState, useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import Header from "./global-components/header/Header";
import Footer from "./global-components/footer/Footer";

import Loading from "./global-components/loading/Loading";

import ScrollTopBtn from "./global-components/scroll-top-btn/ScrollTopBtn";

const Layout = () => {
  const [load, setLoad] = useState(false);
  
  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  }, []);

  const memoizedHeader = useMemo(() => <Header />, []);
  const memoizedFooter = useMemo(() => <Footer />, []);
  const memoizedScrollTop = useMemo(() => <ScrollTopBtn />, []);
  
  return (
    <div>
      {load ? (
        <Loading/>
      ) : (
        <>
          {memoizedHeader}
          <Outlet />
          {memoizedScrollTop}
          {memoizedFooter}
        </>
      )}
    </div>
  );
};

export default Layout;