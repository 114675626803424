import React, { useMemo } from "react";
import { createRoot } from 'react-dom/client';
import Home from "./pages/home/Home";
import Layout from "./Layout";
import Contact from "./pages/contact/Contact";
import Open from "./pages/open/Open";
import Gallery from "./pages/gallery/Gallery";
import Voucher from "./pages/voucher/Voucher";

import Price from './pages/price/Price';
import { BrowserRouter, Routes, Route} from "react-router-dom";

export default function App() {

  const memoizedHome = useMemo(() => <Home />, []);
  const memoizedVoucher = useMemo(() => <Voucher />, []);
  const memoizedPrice = useMemo(() => <Price />, []);
  const memoizedContact = useMemo(() => <Contact />, []);
  const memoizedOpen = useMemo(() => <Open />, []);
  const memoizedGallery = useMemo(() => <Gallery />, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={memoizedHome} />
          <Route path="darkovy-poukaz" element={memoizedVoucher} />
          <Route path="cenik" element={memoizedPrice} />
          <Route path="kontakt" element={memoizedContact} />
          <Route path="oteviraci-doba" element={memoizedOpen} />
          <Route path="galerie" element={memoizedGallery} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);