import React from 'react'

import { lazy, Suspense } from 'react';

import Loading from '../../global-components/loading/Loading';

const VoucherContent = lazy(() => import('./voucher-components/VoucherContent'));
const Breadcrumb = lazy(() => import('../../global-components/breadcrumb/Breadcrumb'));

const Voucher = () => {
  return (
      <>
        <Suspense fallback={<Loading/>}>
          <Breadcrumb navigations={["Domů", "Dárkový poukaz"]} to={["/", "/darkovy-poukaz"]}/>
          <VoucherContent/>
        </Suspense>
      </>
  )
}

export default Voucher