import React from 'react'

import scss from './footer.module.scss';

import {AiOutlineArrowRight} from 'react-icons/ai';

import {OpeningStatus} from '../time/OpeningStatus';

import { Link } from 'react-router-dom';

import { Button } from '../button/Button';

const Footer = () => {

  const isOpen = OpeningStatus();

  let getYear = () => {
    let currentYear = new Date().getFullYear();
    return currentYear;
    };

    function scrollToTop() {
      window.scrollTo(0, 0);
    }

  return (
      <footer className={scss["footer"]}>
          <div className={scss["footer_sections_l"]}>
              <div className={scss["footer_sections_p"]}>
                  <div className={scss["footer_sections"]}>
                      <div className={scss["footer_left"]}>
                          <p className={scss["footer_place"]}>Ve <span>Žluticích</span></p>
                          <h1 className={scss["footer_title"]}>Kadeřnictví <span>Růženka</span></h1>
                      </div>
                      <div className={scss["footer_right"]}>
                          <ul className={scss["footer_links"]}>
                              <Link onClick={scrollToTop} to="/" className={scss["footer_link"]}>Domů</Link>
                              <Link onClick={scrollToTop} to="/cenik" className={scss["footer_link"]}>Ceník</Link>
                              <Link onClick={scrollToTop} to="/darkovy-poukaz"
                                    className={scss["footer_link"]}>Poukaz</Link>
                              <Link onClick={scrollToTop} to="/kontakt" className={scss["footer_link"]}>Kontakt</Link>
                              <Link onClick={scrollToTop} to="/oteviraci-doba" className={scss["footer_link"]}>Otevírací
                                  doba</Link>
                              <Link onClick={scrollToTop} to="/galerie" className={scss["footer_link"]}>Galerie</Link>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
          <Button title="Chci péči a styling"/>

          <p className={scss["footer_i"]}>Růžena Jandová<br/>IČO: 71878211<br/>+420 704 747 541<br/>ruza82@seznam.cz<br/></p>
          <p className={scss["footer_i"]}>Petr Adamčík<br/>IČO: 19954514<br/>+420 773 041 968<br/></p>

          <div className={scss["footer_bottom"]}>
              <p>
                  &copy; {getYear()} Kadeřnictví Růženka | Vytvořili <a
                  href="https://instagram.com/jolu.site?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D">Josef Zeman & Lucie
                  Mertová</a>
              </p>
          </div>
      </footer>
  )
}

export default Footer