import React, { lazy, Suspense } from 'react';

import Loading from '../../global-components/loading/Loading';

const LazyBreadcrumb = lazy(() => import('../../global-components/breadcrumb/Breadcrumb'));
const LazyContactContent = lazy(() => import('./contact-components/ContactContent'));

const Contact = () => {
  return (
    <>
      <Suspense fallback={<Loading/>}>
        <LazyBreadcrumb navigations={["Domů", "Kontakt"]} to={["/", "/kontakt"]} />
        <LazyContactContent />
      </Suspense>
    </>
  );
};

export default Contact;