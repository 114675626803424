import React from "react";

import scss from './button.module.scss';

import { Link } from 'react-router-dom';

import {AiOutlineArrowRight} from 'react-icons/ai';

function scrollToTop() {
  window.scrollTo(0, 0);
}

export function Button({title}) {
  return <Link to="/kontakt" onClick={scrollToTop} className={scss["main_button"]}>{title}<AiOutlineArrowRight className={scss["arrow_text"]} /></Link>;
}
  