import React from 'react';

function isCurrentlyOpen() {
  const currentDay = new Date().getDay();
  const currentHour = new Date().getHours();
  const currentMinute = new Date().getMinutes();

  const openingHours = {
    0: { isOpen: false }, // Neděle
    1: { isOpen: true, startHour: 13, startMinute: 0, endHour: 19, endMinute: 0 },
    2: { isOpen: true, startHour: 9, startMinute: 0, endHour: 17, endMinute: 0 },
    3: { isOpen: true, startHour: 9, startMinute: 0, endHour: 17, endMinute: 0 },
    4: { isOpen: true, startHour: 13, startMinute: 0, endHour: 19, endMinute: 0 },
    5: { isOpen: true, startHour: 9, startMinute: 0, endHour: 17, endMinute: 0 },
    6: { isOpen: false }, // Sobota
  };

  const currentDayOpeningHours = openingHours[currentDay];

  if (!currentDayOpeningHours || !currentDayOpeningHours.isOpen) {
    return false;
  }

  const {
    startHour,
    startMinute,
    endHour,
    endMinute
  } = currentDayOpeningHours;

  // Přidání polední pauzy (např. 12:00 - 13:00)
  const lunchStartHour = 12;
  const lunchStartMinute = 0;
  const lunchEndHour = 13;
  const lunchEndMinute = 0;

  const openingTime = new Date();
  openingTime.setHours(startHour, startMinute);
  
  const closingTime = new Date();
  closingTime.setHours(endHour, endMinute);

  // Kontrola, zda je aktuální čas v rámci otevírací doby nebo polední pauzy
  const currentTime = new Date();
  const isDuringLunch = currentTime >= new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), lunchStartHour, lunchStartMinute) &&
                        currentTime <= new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), lunchEndHour, lunchEndMinute);

  return (currentTime >= openingTime && currentTime <= closingTime) && !isDuringLunch;
}

export function OpeningStatus() {
  const isOpened = isCurrentlyOpen();
  return isOpened;
}